import M from "materialize-css";

export default {
    data() {
        return {
            myInnerWith: window.innerWidth
        }
    },

    mounted() {
        window.addEventListener("resize", this.setMyInnerWidth);
    },
    destroyed() {
        window.removeEventListener("resize", this.setMyInnerWidth);
    },
    computed: {
        isMobile: function() {
            return this.myInnerWith <= 760
        }
    },
    methods: {
        activateSelect: function() {
            var elems = document.querySelectorAll('select');
            M.FormSelect.init(elems, {});
        },
        setMyInnerWidth: function() {
            this.myInnerWith = window.innerWidth
            this.activateSelect()
        }
    }
};