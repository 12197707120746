<template>
  <div>
    <div class="row input-field">
      <div class="col s12 m8 right-align">
        <em>¿Dónde encontrar el número de Orden de Pago?</em>
      </div>
      <div class="col s12 m4 right-align">
        <a
          class="
            btn-comprobante-pago
            waves-effect waves-light
            btn-flat
            modal-trigger
          "
          @click="clickDonde"
          :href="'#' + modalId"
          >Revisa ACÁ</a
        >
      </div>

      <!-- Modal Structure -->
      <div v-bind:id="modalId" class="modal modal-fixed-footer">
        <div class="modal-content">
          <img width="100%" alt="Ejemplo comprobante retiro" :src="imgPath" />
        </div>
        <div class="modal-footer">
          <a
            href="#!"
            class="modal-close waves-effect waves-green btn-small pulse"
            >Cerrar ventana</a
          >
        </div>
      </div>
    </div>
    <div class="row input-field">
      <div class="col s12 m8 right-align">
        <em>¿Perdiste el número de Orden de Pago?</em>
      </div>
      <div class="col s12 m4 right-align">
        <a
          class="
            btn-comprobante-pago
            waves-effect waves-light
            btn-flat
          "
          @click="clickPerdiste"
          :href="perdisteLink"
          target="_blank"
          >Revisa ACÁ</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import M from "materialize-css";
export default {
  data() {
    return {
      perdisteLink:
        "https://www.redsalud.cl/nuestra-red/campanas/recuperacion-numero-de-orden-de-examen?nombreclinica=campanas",
    };
  },
  props: {
    modo: String,
  },
  computed: {
    imgPath() {
      return require(this.modo === "examen"
        ? "../assets/img/imagenMarketing3.png"
        : "../assets/img/comprobante-retiro-examen.png");
    },
    modalId() {
      return "ordenPago" + this.modo + "Example";
    },
  },
  methods: {
    clickDonde() {
      this.$gtag.pageview({
        page_path:
          (this.modo === "examen" ? "/examenes" : "/laboratorio") +
          "/ayuda-nro-orden",
      });
      this.$datadogRum.startView({
        name: (this.modo === "examen" ? "/examenes" : "/laboratorio") +
          "/ayuda-nro-orden",
        service: process.env.VUE_APP_DATADOG_RUM_SERVICE,
        version: process.env.VUE_APP_VERSION
      })

      this.$gtag.event("help_nro_orden", {
        event_category: this.modo === "examen" ? "examenes" : "laboratorio",
        event_label: "help_displayed",
        value: 1,
      });
    },
    clickPerdiste() {
      this.$gtag.event("help_nro_orden_perdida", {
        event_category: this.modo === "examen" ? "examenes" : "laboratorio",
        event_label: "link_help_nro_orden_perdida",
        value: 1,
      });
      // window.location.href = this.perdisteLink;
    },
  },
  mounted() {
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems);
  },
};
</script>

<style scoped>
.btn-comprobante-pago {
  background-color: #57b0ae;
  border-radius: 3px;
  color: #fff;
  padding: 2px 5px;
  text-transform: uppercase;
  font-size: 12px;
}
</style>