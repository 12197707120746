<template>
  <div class="row no-padding-x hoverable">
    <div class="col s12 m4 input-field select_tipo_establecimiento">
      <label class="active" for="tipo_establecimiento_select">Nuestra Red</label>
      <select :class="[{'browser-default': isMobile}]" v-model="tipo_establecimiento" id="tipo_establecimiento_select" name="tipo_establecimiento_select">
          <option value="" disabled selected>Seleccione</option>
          <option value="clinica">Clínicas</option>
          <option value="cmd">Centros Médicos y Dentales</option>
          <option value="">Toda la red</option>
      </select>
    </div>

    <div class="col s12 m8 input-field select_establecimiento" v-show="tipo_establecimiento == ''" >
      <label class="active" for="establecimiento_select">Centro de atención</label>
      <select :class="[{'browser-default': isMobile}]" v-model="establecimiento" id="establecimiento_select" name="establecimiento_select">
        <option value="" disabled selected>Seleccione</option>
        <optgroup v-for="(group, group_key) in establecimientosPorRegion" :key="'g' + group_key" :value="group_key" :label="group.region">
          <option v-for="(option, branch_key) in group.branches" :key="'b' + branch_key" :value="option.nombre">{{option.nombre}}</option>
        </optgroup>
      </select>
    </div>
    <div class="col s12 m8 input-field select_establecimiento" v-show="tipo_establecimiento.length > 0 && tipo_establecimiento == 'cmd'">
      <label class="active" for="establecimiento_select">Centro de atención</label>
      <select :class="[{'browser-default': isMobile}]" v-model="establecimiento" id="establecimiento_select" name="establecimiento_select">
        <option value="" disabled selected>Seleccione</option>
        <optgroup v-for="(group, group_key) in establecimientosCMDPorRegion" :key="'g' + group_key" :value="group_key" :label="group.region">
          <option v-for="(option, branch_key) in group.branches" :key="'b' + branch_key" :value="option.nombre">{{option.nombre}}</option>
        </optgroup>
      </select>
    </div>
    <div class="col s12 m8 input-field select_establecimiento" v-show="tipo_establecimiento.length > 0 && tipo_establecimiento == 'clinica'">
      <label class="active" for="establecimiento_select">Centro de atención</label>
      <select :class="[{'browser-default': isMobile}]" v-model="establecimiento" id="establecimiento_select" name="establecimiento_select">
        <option value="" disabled selected>Seleccione</option>
        <optgroup v-for="(group, group_key) in establecimientosClinicaPorRegion" :key="'g' + group_key" :value="group_key" :label="group.region">
          <option v-for="(option, branch_key) in group.branches" :key="'b' + branch_key" :value="option.nombre">{{option.nombre}}</option>
        </optgroup>
      </select>
    </div>

    <button v-show="false" ref="modalprovidenciaBtn" data-target="modalprovidencia" class="btn modalprovidencia modal-trigger">Modal</button>

  </div>
</template>

<script>
import M from "materialize-css";
/*import DisclaimerProvidencia from "./DisclaimerProvidencia"; */   /*cleivau*/

export default {
  name: "ExamLanding",
  /*cleivau*/
  /*components:{
    DisclaimerProvidencia
  },*/
  data() {
    return {
      clinicaProv: false, /*cleivau*/
      env: '',
      tipo_establecimiento: '',
      establecimiento: '',

      establecimientos: [{
        region: 'Región de Tarapacá',
        Tipo: 'clinica',
        nombre: 'RedSalud Iquique'
      }, {
        region: 'Región de Tarapacá',
        Tipo: 'cmd',
        nombre: 'RedSalud Eleuterio Ramírez'
      }, {
        region: 'Región de Antofagasta',
        Tipo: 'cmd',
        nombre: 'RedSalud Antofagasta'
      }, {
        region: 'Región de Antofagasta',
        Tipo: 'cmd',
        nombre: 'RedSalud Calama'
      }, {
        region: 'Región de Coquimbo',
        Tipo: 'clinica',
        nombre: 'RedSalud Elqui - La Serena'
      }, {
        region: 'Región de Valparaíso',
        Tipo: 'clinica',
        nombre: 'RedSalud Valparaíso'
      }, {
        region: 'Región de Valparaíso',
        Tipo: 'cmd',
        nombre: 'RedSalud Muelle Barón'
      }, {
        region: 'Región de Valparaíso',
        Tipo: 'cmd',
        nombre: 'RedSalud Viña del Mar'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'clinica',
        nombre: 'RedSalud Providencia'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'clinica',
        nombre: 'RedSalud Santiago'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'clinica',
        nombre: 'RedSalud Vitacura'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Agustinas'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Alameda'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Apoquindo'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Arauco - Parque Arauco'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Barrio Independencia'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Conchalí'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud La Dehesa'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud La Florida'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Walker Martínez'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Maipú'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Ñuñoa'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Pedro de Valdivia'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Puente Alto'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud Quilicura'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud San Bernardo'
      }, {
        region: 'Región Metropolitana de Santiago',
        Tipo: 'cmd',
        nombre: 'RedSalud San Miguel'
      }, {
        region: 'Región del Libertador General Bernardo O’Higgins',
        Tipo: 'clinica',
        nombre: 'RedSalud Rancagua'
      }, {
        region: 'Región del Libertador General Bernardo O’Higgins',
        Tipo: 'cmd',
        nombre: 'RedSalud Germán Riesco'
      }, {
        region: 'Región del Ñuble',
        Tipo: 'cmd',
        nombre: 'RedSalud Chillán'
      }, {
        region: 'Región del Bíobío',
        Tipo: 'cmd',
        nombre: 'RedSalud Concepción'
      }, {
        region: 'Región del Bíobío',
        Tipo: 'cmd',
        nombre: 'RedSalud Los Ángeles'
      }, {
        region: 'Región del Bíobío',
        Tipo: 'cmd',
        nombre: 'RedSalud Talcahuano'
      }, {
        region: 'Región de los Ríos',
        Tipo: 'cmd',
        nombre: 'RedSalud Valdivia'
      }, {
        region: 'Región de la Araucanía',
        Tipo: 'clinica',
        nombre: 'RedSalud Mayor Temuco'
      }, {
        region: 'Región de la Araucanía',
        Tipo: 'cmd',
        nombre: 'RedSalud Temuco'
      }, {
        region: 'Región de los Lagos',
        Tipo: 'cmd',
        nombre: 'RedSalud Osorno'
      }, {
        region: 'Región de los Lagos',
        Tipo: 'cmd',
        nombre: 'RedSalud Puerto Montt'
      }, {
        region: 'Región de Magallanes y la Antártica',
        Tipo: 'clinica',
        nombre: 'RedSalud Magallanes'
      }, {
        region: 'Región de Magallanes y la Antártica',
        Tipo: 'cmd',
        nombre: 'RedSalud Punta Arenas'
      }]
    };
  },
  computed: {
    establecimientosPorRegion () {
      return this.regionesEstablecimientos.map(region => {
        return {
          region: region,
          branches: this.establecimientos
            .filter(est => est.region === region)
            .map(est => {
              return {
                Tipo: est.Tipo,
                nombre: est.nombre
              }
            })
        }
      });
    },
    establecimientosCMDPorRegion () {
      return this.regionesEstablecimientos.map(region => {
        return {
          region: region,
          branches: this.establecimientos
            .filter(est => est.region === region && est.Tipo === 'cmd' )
            .map(est => {
              return {
                Tipo: est.Tipo,
                nombre: est.nombre
              }
            })
        }
      }).filter(region => region.branches.length > 0);
    },
    establecimientosClinicaPorRegion () {
      return this.regionesEstablecimientos.map(region => {
        return {
          region: region,
          branches: this.establecimientos
            .filter(est => est.region === region && est.Tipo === 'clinica' )
            .map(est => {
              return {
                Tipo: est.Tipo,
                nombre: est.nombre
              }
            })
        }
      }).filter(region => region.branches.length > 0);
    },
    regionesEstablecimientos () {
        return [...new Set(this.establecimientos.map(est => est.region))];
    }
  },
  methods: {
    continueBranchFlow () {
      if(this.establecimiento.length > 0) {
        this.$emit('branchSelected', this.establecimiento);
      }
    }
  },
  mounted() {
    M.AutoInit();
    M.updateTextFields();
    this.env = process.env.NODE_ENV;
  },
  watch: {
    establecimiento (opcion) {
      this.clinicaProv=false; /*cleivau*/
      switch (opcion) {
        case 'RedSalud Providencia':
          /*this.$refs.modalprovidenciaBtn.click();*/ /*cleivau*/
          this.clinicaProv=true; /*cleivau*/
          this.$emit('branchSelected', this.establecimiento);/*cleivau*/
          break;
        default:
          this.$emit('branchSelected', this.establecimiento);
          break;
      }
    },
    /*cleivau*/
    clinicaProv:function(){
      this.$root.$emit("selProvi",this.clinicaProv);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.select_tipo_establecimiento div.select-wrapper{
  padding-right: 26px;
}
.select_establecimiento div.select-wrapper{
  padding-right: 26px;
}
.select_tipo_establecimiento input.select-dropdown.dropdown-trigger{
  text-overflow: ellipsis;
}
</style>
